<template>
  <router-view :key="$route.fullPath" />
  <Toasts />
  <div class="mt-3 pb-5" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Toasts from '@app/components/generic/toasts.vue';

export default defineComponent({
  components: {
    Toasts,
  },
});

</script>

import { JSONContent } from '@tiptap/core';
import ImageRepository from '@app/repositories/imageRepository';
import EntityRepository from '@app/repositories/entityRepository';
import CommentRepository from '@app/repositories/commentRepository';
import store from '@app/store';
import emitter from 'tiny-emitter/instance';
import {
  EntityType, Universe, Relationship, Image, EntityComment,
} from '@app/models';

// In some cases the API does not return all the entity data
export class AbridgedEntity {
  id: string;

  universeId: string;

  name: string;

  images: Image[];

  entityTypeId: string;

  tags: string[];

  constructor(obj: any) {
    this.id = obj.id;
    this.universeId = obj.universeId;
    this.name = obj.name;
    this.entityTypeId = obj.entityTypeId;
    if (obj.images) {
      this.images = obj.images.map((image: any) => new Image(image));
    }
    else {
      this.images = [];
    }
    this.tags = obj.tags;
  }

  getImageThumbnailUrl(entityType: EntityType): string {
    if (this.images.length > 0) {
      return `${COW_USER_IMAGE_URL}/thumbnails/${this.images[0].id}.${this.images[0].thumbnailExtension}`;
    }

    if (entityType.image) {
      return `${COW_USER_IMAGE_URL}/thumbnails/${entityType.image.id}.${entityType.image.thumbnailExtension}`;
    }

    return '/images/default.jpg';
  }

  getImageThumbnailUrlByUniverse(universe: Universe): string {
    if (this.images.length > 0) {
      return `${COW_USER_IMAGE_URL}/thumbnails/${this.images[0].id}.${this.images[0].thumbnailExtension}`;
    }

    const entityType = universe.getEntityType(this.entityTypeId);

    if (entityType?.image) {
      return `${COW_USER_IMAGE_URL}/thumbnails/${entityType.image.id}.${entityType.image.thumbnailExtension}`;
    }

    return '/images/default.jpg';
  }

  getImageUrl(entityType: EntityType): string {
    if (this.images.length > 0) {
      return `${COW_USER_IMAGE_URL}/${this.images[0].id}.${this.images[0].extension}`;
    }

    if (entityType.image) {
      return `${COW_USER_IMAGE_URL}/${entityType.image.id}.${entityType.image.extension}`;
    }

    return '/images/default.jpg';
  }

  getImageUrls(): string[] {
    const urls: string[] = [];
    this.images.forEach((image: Image) => {
      urls.push(`${COW_USER_IMAGE_URL}/${image.id}.${image.extension}`);
    });

    return urls;
  }
}

export default class Entity extends AbridgedEntity {
  textFields: Record<string, object>;

  relationshipFields: Record<string, Array<Relationship>>;

  constructor(obj: any) {
    super(obj);
    if (obj.textFields) {
      this.textFields = obj.textFields;
    }
    else {
      this.textFields = {};
    }

    // Conversion from plain object is a little tricky here
    // The properties of obj.relationshipFields the ids of each relationship field as strings
    // The values of these properties are arrays of raw relationship objects
    if (obj.relationshipFields) {
      this.relationshipFields = Object.fromEntries(
        Object.entries(obj.relationshipFields).map(
          ([fieldId, relationshipsObj]) => [fieldId, (relationshipsObj as any).map(
            (relationship: any) => new Relationship(relationship),
          )],
        ),
      );
    }
    else {
      this.relationshipFields = {};
    }
  }

  async rename(newName: string): Promise<Entity> {
    this.name = newName;
    return EntityRepository.update(
      this.universeId,
      {
        name: this.name,
        id: this.id,
      },
    );
  }

  async setTextField(fieldId: string, document: object): Promise<{document: object}> {
    const textFieldValue: { document: any } = await EntityRepository.createOrUpdateTextFieldValue(
      this.universeId,
      this.id,
      fieldId,
      { document: document },
    );
    this.textFields[fieldId] = textFieldValue.document;
    return { document: textFieldValue.document };
  }

  async addImage(file: File): Promise<void> {
    return ImageRepository.addEntityImage(this.universeId, this.id, file).then((image) => {
      this.images.push(image);
    });
  }

  async addImageByUrl(url: string): Promise<void> {
    return ImageRepository.addEntityImageByUrl(this.universeId, this.id, url).then((image) => {
      this.images.push(image);
    });
  }

  async addTag(tag: string): Promise<Entity> {
    this.tags.push(tag);
    return EntityRepository.update(
      this.universeId,
      {
        tags: this.tags,
        id: this.id,
      },
    );
  }

  async setTags(tags: string[]): Promise<Entity> {
    this.tags = tags;
    return EntityRepository.update(
      this.universeId,
      {
        tags: tags,
        id: this.id,
      },
    );
  }

  async delete(): Promise<void> {
    return EntityRepository.delete(this.universeId, this.id).then(() => {
      store.state.toasts.push(
        {
          componentName: 'UndoDeleteToast',
          key: this.id,
          message: `Deleted ${this.name}`,
          undoDelete: async() => {
            await EntityRepository.undoDelete(this.universeId, this.id);
            emitter.emit('undoDeleteEntity', this);
          },
        },
      );
    });
  }

  async getComments(): Promise<Array<EntityComment>> {
    return CommentRepository.list(this.universeId, this.id);
  }

  async addComment(document: JSONContent) {
    return CommentRepository.create(this.universeId, this.id, document);
  }

  async deleteImage(image: Image): Promise<void> {
    const index = this.images.findIndex((candidateImage) => candidateImage.id === image.id);
    this.images.splice(index, 1);
    return ImageRepository.deleteEntityImage(this.universeId, this.id, image.id).then(() => {
      store.state.toasts.push(
        {
          componentName: 'UndoDeleteToast',
          key: image.id,
          message: `Deleted image for "${this.name}"`,
          undoDelete: async() => {
            await ImageRepository.undoDeleteEntityImage(this.universeId, this.id, image.id);

            let insertIndex = 0;
            for (const existingImage of this.images) {
              if (existingImage.position > image.position) {
                break;
              }
              insertIndex += 1;
            }

            this.images.splice(insertIndex, 0, image);
          },
        },
      );
    });
  }

  async deleteRelationship(relationship: Relationship, index: number) {
    await EntityRepository.deleteRelationship(
      relationship.universeId,
      relationship.from.id,
      relationship.fieldId,
      relationship.id,
    );
    this.relationshipFields[relationship.fieldId].splice(index, 1);
    store.state.toasts.push(
      {
        componentName: 'UndoDeleteToast',
        key: relationship.id,
        message: `Deleted relationship from "${this.name}" to "${relationship.to.name}"`,
        undoDelete: async() => {
          await EntityRepository.undoDeleteRelationship(
            this.universeId,
            this.id,
            relationship.fieldId,
            relationship.id,
          );

          this.relationshipFields[relationship.fieldId].splice(index, 0, relationship);
        },
      },
    );
  }

  async addRelationship(fieldId: string, otherEntityId: string): Promise<void> {
    return EntityRepository.createRelationship(this.universeId, this.id, fieldId, otherEntityId).then(
      (newRelationship) => {
        if (this.relationshipFields[fieldId] === undefined) {
          this.relationshipFields[fieldId] = [];
        }
        let inserted = false;
        const newRelationshipName = newRelationship.to.name.toLowerCase();
        for (let i = 0; i < this.relationshipFields[fieldId].length; i += 1) {
          if (newRelationshipName < this.relationshipFields[fieldId][i].to.name.toLowerCase()) {
            this.relationshipFields[fieldId].splice(i, 0, newRelationship);
            inserted = true;
            break;
          }
        }
        if (!inserted) {
          this.relationshipFields[fieldId].push(newRelationship);
        }
      },
    );
  }
}

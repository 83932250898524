import { User } from '@app/models';
import Roles from '@app/authorization/roles';

export default class EntityComment {
  id: string;

  text: string;

  document: any;

  poster: { id: string; name: string };

  entityId: string;

  universeId: string;

  createdAt: string;

  editedAt?: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.text = obj.text;
    this.document = obj.document;
    this.universeId = obj.universeId;
    this.entityId = obj.entityId;
    this.poster = obj.poster;
    this.createdAt = obj.createdAt;
    this.editedAt = obj.editedAt;
  }

  canDelete(user: User, roles: Roles) {
    return this.poster.id === user.id || roles.ownsUniverse();
  }

  canEdit(user: User) {
    return this.poster.id === user.id;
  }
}

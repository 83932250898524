import { createRouter, createWebHashHistory } from 'vue-router';
import emitter from 'tiny-emitter/instance';
import store from './store';

const universesView = () => import('./views/universes.vue');
const universeView = () => import('./views/universe.vue');
const loginView = () => import('./views/login.vue');
const signupView = () => import('./views/signup.vue');
const tutorialView = () => import('./views/tutorial.vue');
const listEntitiesView = () => import('./views/entityList.vue');
const searchEntitiesView = () => import('./views/entitySearch.vue');
const entityDetailsView = () => import('./views/entityDetails.vue');
const accountSettingsView = () => import('./views/accountSettings.vue');
const subscribeView = () => import('./views/subscribe.vue');
const subscriptionSucceededView = () => import('./views/subscriptionSucceeded.vue');
const resetPasswordView = () => import('./views/resetPassword.vue');
const recoverPasswordView = () => import('./views/recoverPassword.vue');

const adminView = () => import('./views/admin/main.vue');
const adminEntityTypeView = () => import('./views/admin/entityType.vue');
const adminUserList = () => import('./views/admin/users.vue');
const adminUserDetails = () => import('./views/admin/user.vue');

const entityTypeView = () => import('./views/entityType.vue');

const requireLogin = () => {
  if (store.state.loggedInUser === undefined) {
    return '/login';
  }
  return undefined;
};

const routes = [
  {
    // I named this path /siteAdmin instead of /admin so that we get less hackers poking around
    // This isn't security through obscurity, it's just to avoid noise in our logs
    path: '/siteAdmin',
    component: adminView,
    name: 'admin',
    beforeEnter: requireLogin,
    meta: { isAdminPage: true },
  },
  {
    path: '/siteAdmin/defaultEntityType/:entityTypeId',
    component: adminEntityTypeView,
    name: 'adminDefaultEntityTypeView',
    beforeEnter: requireLogin,
    meta: { isAdminPage: true },
  },
  {
    path: '/siteAdmin/universes/:universeId/entityTypes/:entityTypeId',
    component: adminEntityTypeView,
    name: 'adminUniverseEntityTypeView',
    beforeEnter: requireLogin,
    meta: { isAdminPage: true },
  },
  {
    path: '/siteAdmin/users',
    component: adminUserList,
    name: 'adminUserList',
    beforeEnter: requireLogin,
    meta: { isAdminPage: true },
  },
  {
    path: '/siteAdmin/users/:userId',
    component: adminUserDetails,
    name: 'adminUserDetails',
    beforeEnter: requireLogin,
    meta: { isAdminPage: true },
  },

  {
    path: '/',
    component: universesView,
    name: 'universes',
    beforeEnter: requireLogin,
  },
  {
    path: '/universes/:universeId',
    component: universeView,
    name: 'universe',
    beforeEnter: requireLogin,
  },
  {
    path: '/universes/:universeId/entityTypes/:entityTypeId/entities',
    name: 'entitiesList',
    component: listEntitiesView,
    meta: {
      scrollBasedOnBrowserHistory: true,
    },
    beforeEnter: requireLogin,
  },
  {
    path: '/universes/:universeId/entities/search',
    component: searchEntitiesView,
    meta: {
      scrollBasedOnBrowserHistory: true,
    },
    beforeEnter: requireLogin,
  },
  {
    path: '/universes/:universeId/entityTypes/:entityTypeId/entities/:entityId',
    name: 'entity',
    component: entityDetailsView,
    meta: {
      scrollBasedOnBrowserHistory: true,
    },
    beforeEnter: requireLogin,
  },
  {
    path: '/universes/:universeId/entityTypes/:entityTypeId',
    name: 'entityType',
    component: entityTypeView,
    beforeEnter: requireLogin,
  },
  {
    path: '/tutorial',
    component: tutorialView,
    beforeEnter: requireLogin,
  },
  {
    path: '/accountSettings',
    component: accountSettingsView,
    beforeEnter: requireLogin,
  },
  {
    path: '/resetPassword',
    component: resetPasswordView,
  },
  {
    path: '/recoverPassword',
    component: recoverPasswordView,
  },
  {
    path: '/login',
    component: loginView,
  },
  {
    path: '/signup',
    component: signupView,
  },
  {
    path: '/subscribe',
    component: subscribeView,
  },
  {
    path: '/subscriptionSucceeded',
    component: subscriptionSucceededView,
  },
];

const history = createWebHashHistory();

const router = createRouter({
  routes,
  history,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      // We allow some endpoints to manually handle when they scroll. They can opt into this by having the meta property
      // `scrollBasedOnBrowserHistory` set in their route above.
      const useBrowserHistoryBasedScroll = to.matched.some((m) => m.meta.scrollBasedOnBrowserHistory);
      if (useBrowserHistoryBasedScroll) {
        return new Promise((resolve) => {
          // To scroll, they need to emit a `triggerScrollBasedOnBrowserHistory` event on `emitter`. You would want
          // to do this after rendering data fetched from an API. So it's best to emit using `this.$nextTick()` right
          // after finishing your fetch.
          emitter.once('triggerScrollBasedOnBrowserHistory', () => {
            resolve(savedPosition);
          });
        });
      }

      // Otherwise, by default, we try to scroll to the place you used to be.
      return savedPosition;
    }
    // If you didn't come to this page via the back-button, start at the top of the page.
    return { left: 0, top: 0 };
  },
});

// Clean up any changes made by bootstrap modals
router.beforeEach((_to, _from, next) => {
  document.querySelectorAll('.modal-backdrop').forEach((modal) => {
    if (modal.parentNode) {
      modal.parentNode.removeChild(modal);
    }
  });
  document.querySelectorAll('body').forEach((body) => {
    const { style }: any = body;
    style.overflow = '';
    style['padding-right'] = '';
    body.classList.remove('modal-open');
  });

  next();
});

export default router;

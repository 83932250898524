import {
  AccessLevel,
  nonePermission,
  isAtLeastEditor,
} from '@app/authorization/accessLevel';
import roleApiClient from '@app/authorization/roleApiClient';
import permissionsApiClient from '@app/authorization/permissionsApiClient';

export type EntityTypePermission = {
  id: string;
  entityTypeId: string;
  accessLevel: AccessLevel;
}

export type EntityPermission = {
  id: string;
  entityId: string;
  accessLevel: AccessLevel;
}

export type FieldPermission = {
  id: string;
  fieldId: string;
  accessLevel: AccessLevel;
}

export default class CustomRole {
  id: string;

  name: string;

  universeId: string;

  entityTypePermissions: Record<string, AccessLevel>;

  entityPermissions: Record<string, AccessLevel>;

  fieldPermissions: Record<string, AccessLevel>;

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.universeId = obj.universeId;

    this.entityTypePermissions = {};
    if (obj.entityTypePermissions) {
      for (const permission of obj.entityTypePermissions as EntityTypePermission[]) {
        this.entityTypePermissions[permission.entityTypeId] = permission.accessLevel;
      }
    }

    this.entityPermissions = {};
    if (obj.entityPermissions) {
      for (const permission of obj.entityPermissions as EntityPermission[]) {
        this.entityPermissions[permission.entityId] = permission.accessLevel;
      }
    }

    this.fieldPermissions = {};
    if (obj.fieldPermissions) {
      for (const permission of obj.fieldPermissions as FieldPermission[]) {
        this.fieldPermissions[permission.fieldId] = permission.accessLevel;
      }
    }
  }

  ownsUniverse(): boolean {
    return false;
  }

  canEditUniverse(): boolean {
    return false;
  }

  canEditEntity(entity: {id: string, entityTypeId: string}): boolean {
    const accessLevel = this.entityPermissions[entity.id]
      || this.entityTypePermissions[entity.entityTypeId]
      || nonePermission;
    return isAtLeastEditor(accessLevel);
  }

  canEditField(field: {id: string, entityTypeId: string}): boolean {
    const accessLevel = this.fieldPermissions[field.id]
      || this.entityTypePermissions[field.entityTypeId]
      || nonePermission;
    return isAtLeastEditor(accessLevel);
  }

  canEditFieldOnEntity(entityId: string, field: {id: string, entityTypeId: string}): boolean {
    const accessLevel = this.fieldPermissions[field.id]
      || this.entityPermissions[entityId]
      || this.entityTypePermissions[field.entityTypeId]
      || nonePermission;
    return isAtLeastEditor(accessLevel);
  }

  canCreateEntityOfType(entityTypeId: string): boolean {
    const accessLevel = this.entityTypePermissions[entityTypeId] || nonePermission;
    return isAtLeastEditor(accessLevel);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditEntityType(_entityTypeId: string): boolean {
    return false;
  }

  async rename(newName: string) {
    this.name = newName;
    await roleApiClient.update(this.id, { name: newName });
  }

  async setEntityTypePermission(entityTypeId: string, permission: AccessLevel) {
    this.entityTypePermissions[entityTypeId] = permission;
    await permissionsApiClient.setEntityTypePermission(this.id, entityTypeId, permission);
  }

  async setEntityPermission(entityId: string, permission: AccessLevel) {
    this.entityPermissions[entityId] = permission;
    await permissionsApiClient.setEntityPermission(this.id, entityId, permission);
  }

  async setFieldPermission(fieldId: string, permission: AccessLevel) {
    this.fieldPermissions[fieldId] = permission;
    await permissionsApiClient.setFieldPermission(this.id, fieldId, permission);
  }
}

import {
  Universe, UniverseMetadata, EntityType, PremiumFeature,
} from '@app/models';
import {
  get, post, put, patch, delete_,
} from '@app/utilities/fetchWrapper';
import { HttpError, CowApiError } from '@app/utilities/errorHandling';

export default {
  list: async function(): Promise<Array<Universe>> {
    const universes = await get<Partial<Array<Universe>>>(`${COW_API_URL}/universes`);
    return universes.map((universe) => new Universe(universe));
  },
  get: async function(
    universeId: string,
  ): Promise<Universe | HttpError.NotFound | HttpError.Gone | HttpError.Forbidden> {
    try {
      return new Universe(await get(`${COW_API_URL}/universes/${universeId}`));
    }
    catch (error) {
      if (error instanceof CowApiError) {
        if (error.httpStatus === 404) {
          return HttpError.NotFound;
        }
        if (error.httpStatus === 410) {
          return HttpError.Gone;
        }
        if (error.httpStatus === 403) {
          return HttpError.Forbidden;
        }
      }
      throw error;
    }
  },
  update: async function(universe: Partial<Universe>): Promise<Universe> {
    return new Universe(await patch(
      `${COW_API_URL}/universes/${universe.id}`,
      { body: JSON.stringify(universe) },
    ));
  },
  create: async function(universe: Partial<Universe>): Promise<Universe> {
    return new Universe(await post(
      `${COW_API_URL}/universes`,
      { body: JSON.stringify(universe) },
    ));
  },
  delete: async function(universeId: string): Promise<Universe> {
    return new Universe(await delete_(`${COW_API_URL}/universes/${universeId}`));
  },
  undoDelete: async function(universeId: string): Promise<Universe> {
    return new Universe(
      await patch(
        `${COW_API_URL}/universes/deleted/${universeId}`,
        { body: JSON.stringify({ deletedAt: null }) },
      ),
    );
  },
  includeEntityTypes: async function(
    universeId: string,
    entityTypeIds: Array<string>,
  ): Promise<Array<EntityType>> {
    const entityTypes: Array<Partial<EntityType>> = await post(
      `${COW_API_URL}/universes/${universeId}/entity_types/multiple`,
      { body: JSON.stringify({ ids: entityTypeIds }) },
    );
    return entityTypes.map((entityType) => new EntityType(entityType));
  },
  reorderEntityTypes: async function(universeId: string, order: Array<string>): Promise<void> {
    return put(
      `${COW_API_URL}/universes/${universeId}/entity_types/order`,
      { body: JSON.stringify({ ids: order }) },
    );
  },
  searchTags: async function(universeId: string, query: string): Promise<Array<string>> {
    return get(`${COW_API_URL}/universes/${universeId}/tags?query=${query}`);
  },
  configurePremiumFeature: async function(universeId: string, name: string, enabled: boolean): Promise<PremiumFeature> {
    return put(
      `${COW_API_URL}/universes/${universeId}/premium_features/${name}`,
      { body: JSON.stringify({ enabled: enabled }) },
    );
  },
  getMetadata: async function(universeId: string): Promise<UniverseMetadata> {
    return get(`${COW_API_URL}/universes/${universeId}/metadata`);
  },
};

export const ownerPermission = 'Owner';
export const editorPermission = 'Editor';
export const viewerPermission = 'Viewer';
export const nonePermission = 'None';

export type AccessLevel =
  typeof ownerPermission
  | typeof editorPermission
  | typeof viewerPermission
  | typeof nonePermission;

const isAtLeast = (level: AccessLevel, requiredLevel: AccessLevel): boolean => {
  const order = [
    nonePermission,
    viewerPermission,
    editorPermission,
    ownerPermission,
  ];
  return order.indexOf(level) >= order.indexOf(requiredLevel);
};

export const isOwner = (level: AccessLevel): boolean => level === ownerPermission;
export const isAtLeastEditor = (level: AccessLevel): boolean => isAtLeast(level, editorPermission);
export const isAtLeastViewer = (level: AccessLevel): boolean => isAtLeast(level, viewerPermission);

import store from '@app/store';
import CustomRole from '@app/authorization/customRole';
import BuiltInRole from '@app/authorization/builtInRole';

export default class Roles {
  roles: Array<BuiltInRole | CustomRole>;

  constructor() {
    this.roles = [];
  }

  add(role: BuiltInRole | CustomRole) {
    this.roles.push(role);
  }

  ownsUniverse(ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.ownsUniverse();
    }

    return this.roles.some((role) => role.ownsUniverse());
  }

  canEditUniverse(ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.canEditUniverse();
    }

    return this.roles.some((role) => role.canEditUniverse());
  }

  canCreateEntityOfType(entityTypeId: string, ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.canCreateEntityOfType(entityTypeId);
    }

    return this.roles.some((role) => role.canCreateEntityOfType(entityTypeId));
  }

  canEditEntity(entity: {id: string, entityTypeId: string}, ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.canEditEntity(entity);
    }

    return this.roles.some((role) => role.canEditEntity(entity));
  }

  canEditField(field: {id: string, entityTypeId: string}, ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.canEditField(field);
    }

    return this.roles.some((role) => role.canEditField(field));
  }

  canEditFieldOnEntity(entityId: string, field: {id: string, entityTypeId: string}, ignoreActingAs?: boolean): boolean {
    if (!ignoreActingAs && store.state.actingAs !== undefined) {
      return store.state.actingAs.canEditFieldOnEntity(entityId, field);
    }

    return this.roles.some((role) => role.canEditFieldOnEntity(entityId, field));
  }
}
